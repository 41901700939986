<template>
  <div class="user">
    <img class="background" src="../../assets/image/background.png" alt srcset />
    <header>
      <img src="../../assets/image/logo.png" alt srcset />
    </header>
    <div class="buttonPage" v-if="index==0">
      <div class="buttonBox">
        <div class="button" @click="enroll">立即注册</div>
        <div class="button" @click="signIn">立即登录</div>
      </div>
    </div>
    <div class="logonPage" v-if="index==1">
      <div class="logonBox">
        <div class="butBox">
          <div class="phoneBox">
            <div class="phone butbg">
              <van-row>
                <van-col span="2">
                  <img src="../../assets/image/phone.png" alt />
                </van-col>
                <van-col class="text" span="6">手机号&nbsp;&nbsp;|</van-col>
                <van-col span="16">
                  <input type="digit" v-model.trim="formData.phone" />
                </van-col>
              </van-row>
            </div>
          </div>
          <div class="codeBox">
            <van-row>
              <van-col span="12" class="code butbg">
                <van-row>
                  <van-col span="3">
                    <img src="../../assets/image/code.png" alt />
                  </van-col>
                  <van-col class="text" span="9">验证码&nbsp;&nbsp;|</van-col>
                  <van-col span="6">
                    <input type="digit" v-model.trim="formData.code" />
                  </van-col>
                </van-row>
              </van-col>
              <van-col span="7" class="send butbg" @click="getCode">获取验证码</van-col>
            </van-row>
          </div>
          <div class="submitBox">
            <div class="submit butbg" @click="submit">提交</div>
          </div>
          <div class="tipsBox">
            <div>报名成功后，需等待1个工作日审核方可登录</div>
          </div>
        </div>
      </div>
    </div>
    <div class="qrPage" v-if="index==2">
      <van-divider :style="dividerStyle">参会二维码</van-divider>
      <div class="logout">
        <span class="logout_text" @click="logout">退出登录</span>
      </div>
      <vue-qr class="vue-qr" :text="code" :size="200"></vue-qr>
      <div class="reminder">
        <div class="button" @click="meals">用餐提醒</div>
        <!-- <div>用餐提醒</div>
        <div>{{buffet}}</div>-->
      </div>
      <div class="matters">
        <div class="title">注意事项</div>
        <div class="cell" @click="remind(0)">
          <div class="name">参会注意事项提醒</div>
          <div class="icon">
            <van-icon name="play" size="26" color="#ffffff" />
          </div>
        </div>
        <div class="cell" @click="remind(1)">
          <div class="name">防疫安全</div>
          <div class="icon">
            <van-icon name="play" size="26" color="#ffffff" />
          </div>
        </div>
      </div>
    </div>
    <div class="expectPage" v-if="index==3">
      <div class="expect">敬请期待...</div>
    </div>
    <div class="remindPage">
      <van-popup v-model="remindShow">
        <div class="block">
          <div class="remind_title">{{remindList.remindTitle}}</div>
          <div class="remind_content" v-html="remindList.remindContent"></div>
        </div>
        <div class="close" @click="close">
          <img src="../../assets/image/close.png" />
        </div>
      </van-popup>
    </div>
    <div class="remindPage">
      <van-popup v-model="mealsShow">
        <div class="mealsBox">
          <div class="mealsList" v-if="job=='讲者'">
            <div class="mealsList_title date" >日期</div>
            <div class="mealsList_title time" >时间</div>
            <div class="mealsList_title place">地点</div>
            <div class="mealsList_title meals">餐式</div>
            <div class="date" >9-10</div>
            <div class="time" >11:30-13:30</div>
            <div class="place">二层自助餐厅</div>
            <div class="meals">自助餐</div>
            <div class="date" >9-10</div>
            <div class="time" >17:30-20:30</div>
            <div class="place">四层翰林厅</div>
            <div class="meals">鸡尾酒会暨欢迎晚宴</div>
            <div class="date" >9-11</div>
            <div class="time" >11:30-13:30</div>
            <div class="place">二层自助餐厅</div>
            <div class="meals">自助餐</div>
            <div class="date" >9-11</div>
            <div class="time" >17:30-20:30</div>
            <div class="place">二层自助餐厅</div>
            <div class="meals">自助餐</div>
            <div class="date" >9-12</div>
            <div class="time" >13:00</div>
            <div class="place">四层翰林厅1-2</div>
            <div class="meals">闭幕午宴</div>
          </div>
          <div class="mealsList" v-if="job=='参会者'">
            <div class="mealsList_title date" >日期</div>
            <div class="mealsList_title time" >时间</div>
            <div class="mealsList_title place">地点</div>
            <div class="mealsList_title meals">餐式</div>
            <div class="date" >9-10</div>
            <div class="time" >11:30-13:30</div>
            <div class="place">四楼茶歇台</div>
            <div class="meals">盒式简餐</div>
            <div class="date" >9-10</div>
            <div class="time" >17:00-18:30</div>
            <div class="place">四层翰林厅</div>
            <div class="meals">鸡尾酒会暨欢迎晚宴</div>
            <div class="date" >9-11</div>
            <div class="time" >11:30-13:30</div>
            <div class="place">四楼茶歇台</div>
            <div class="meals">盒式简餐</div>
            <!-- <div class="date" >9-11</div>
            <div class="time" >17:30-20:30</div>
            <div class="place">二层自助餐厅</div>
            <div class="meals">自助餐</div> -->
            <div class="date" >9-12</div>
            <div class="time" >13:00</div>
            <div class="place">四层翰林厅</div>
            <div class="meals">闭幕午宴</div>
          </div>
          <div class="nealsBuy" v-if="job=='参会者'">以上用餐请在报名系统上购买</div>
          <div class="mealsTips">
            凭借参会证二维码进入餐厅，二维码每场有且只能扫一次，请勿将自己的胸卡借给别人。
          </div>
        </div>
        <div class="close" @click="close">
          <img src="../../assets/image/close.png" />
        </div>
      </van-popup>
    </div>
    <div class="footer-fill"></div>
    <el-footer :index="2" />
  </div>
</template>

<script>
import vueQr from "vue-qr";
import footer from "../../components/footer/footer-tempate.vue";
import { getCode, login, getUserinfo, getConfig } from "../../api/index.js";
import storage from "@/utils/storage";
import { Toast } from "vant";
export default {
  components: {
    "el-footer": footer,
    vueQr
  },
  data() {
    return {
      index: 0,
      code: "",
      buffet: "",
      remindShow: false,
      mealsShow: false,
      job:"",
      formData: {
        phone: "",
        code: ""
      },
      dividerStyle: {
        color: "#FFFFFF",
        borderColor: "#fff",
        padding: "0 25%",
        "font-size": "16px"
      },
      remindList: {
        remindTitle: "",
        remindContent: ""
      },
      reminds: {
        partakeTitle: "",
        partakeContent: "",
        antiepidemicTitle: "",
        antiepidemicContent: ""
      }
    };
  },
  methods: {
    signIn() {
      this.index = 1;
    },
    remind(index) {
      if (index == 0) {
        this.remindList.remindTitle = this.reminds.partakeTitle;
        this.remindList.remindContent = this.reminds.partakeContent;
        this.remindShow = true;
      } else if (index == 1) {
        this.remindList.remindTitle = this.reminds.antiepidemicTitle;
        this.remindList.remindContent = this.reminds.antiepidemicContent;
        this.remindShow = true;
      }
    },
    meals() {
      this.mealsShow = true;
    },
    close() {
      this.remindShow = false;
      this.mealsShow = false;
    },
    submit() {
      if (!this.phoneValidator()) {
        return false;
      }
      if (!this.codeValidator()) {
        return false;
      }
      login(this.formData)
        .then(res => {
          if (res.code == 1) {
            localStorage.setItem("token", res.data.userinfo.token);
            this.getUserinfo();
          } else {
            this.$router.push("/information");
          }
        })
        .catch(res => {
          console.log(res);
        });
    },
    enroll() {
      window.location.href = "http://lxi.me/yz3yb";
    },
    getCode() {
      if (!this.phoneValidator()) {
        return false;
      }
      getCode(this.formData)
        .then(res => {
          Toast.success("发送成功");
        })
        .catch(res => {
          console.log(res);
        });
    },
    phoneValidator() {
      let reg_phone = /^(1)\d{10}$/;
      if (
        this.formData.phone == "" ||
        this.formData.phone == null ||
        !reg_phone.test(this.formData.phone)
      ) {
        Toast.success("请输入正确的手机号");
        return false;
      }
      return true;
    },
    codeValidator() {
      if (
        this.formData.code == "" ||
        this.formData.code == null ||
        !/^\d{6}$/.test(this.formData.code)
      ) {
        Toast.success("验证码错误");
        return false;
      }
      return true;
    },
    logout() {
      localStorage.removeItem("token");
      this.index = 0;
    },
    getUserinfo() {
      getUserinfo()
        .then(res => {
          this.buffet = res.data.buffet;
          this.code = res.data.code;
          this.job=res.data.job;
          this.index = 2;
        })
        .catch(err => {
          this.logout();
        });
    },
    getConfig() {
      getConfig({ name: "user" })
        .then(res => {
          if (res.data == "0") {
            this.index = 3;
          } else {
            this.index = 0;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getremind() {
      getConfig({ name: "partakeTitle" })
        .then(res => {
          this.reminds.partakeTitle = res.data;
        })
        .catch(err => {
          console.log(err);
        });
      getConfig({ name: "partakeContent" })
        .then(res => {
          this.reminds.partakeContent = res.data;
        })
        .catch(err => {
          console.log(err);
        });
      getConfig({ name: "antiepidemicTitle" })
        .then(res => {
          this.reminds.antiepidemicTitle = res.data;
        })
        .catch(err => {
          console.log(err);
        });
      getConfig({ name: "antiepidemicContent" })
        .then(res => {
          this.reminds.antiepidemicContent = res.data;
          console.log(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  created() {
    this.getConfig();
    if (localStorage.getItem("token")) {
      this.getUserinfo();
    }
    this.getremind();
  }
};
</script>

<style lang="less" scoped>
.user {
  min-height: 100vh;
  // background-image: url("../../assets/image/background.png");
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  .background {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    position: fixed;
    background-size: 100% 100%;
    z-index: -1;
  }
  .butbg {
    background-image: url("../../assets/image/userbutton.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-size: 10px;
    font-weight: bold;
    border-radius: 4px;
    height: 33px;
    line-height: 33px;
    color: #ffffff;
  }
  header {
    padding: 10px 0;
    width: 80%;
    margin: 0 auto;
    img {
      max-width: 100%;
    }
  }
  .buttonPage {
    margin-top: 20%;
    .buttonBox {
      height: 20%;
      .button {
        background-image: url("../../assets/image/userbutton.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 138px;
        height: 33px;
        font-size: 14px;
        font-weight: bold;
        line-height: 33px;
        margin: 80px auto;
        text-align: center;
        color: #ffffff;
      }
    }
  }
  .logonPage {
    .logonBox {
      width: 327px;
      height: 279px;
      background-color: #342048;
      margin: 0 auto;
      .butBox {
        margin: 28px;
        .phoneBox {
          padding-top: 40px;
          .phone {
            height: 33px;
            img {
              width: 12px;
              height: 16px;
              margin: 8px 0 8px 10px;
            }
            .text {
              padding-left: 5px;
            }
            input {
              height: 26px;
              background-color: transparent;
              border: none;
            }
          }
        }
        .codeBox {
          margin-top: 30px;
          .code {
            height: 33px;
            width: 175px;
            img {
              width: 12px;
              height: 16px;
              margin: 8px 0 8px 10px;
            }
            .text {
              padding-left: 5px;
            }
            input {
              height: 26px;
              background-color: transparent;
              width: 56px;
              border: none;
            }
          }
          .send {
            width: 90px;
            margin-left: 5px;
            text-align: center;
          }
        }
        .submitBox {
          margin-top: 30px;
          .submit {
            margin: 0 auto;
            width: 140px;
            text-align: center;
          }
        }
        .tipsBox {
          margin-top: 30px;
          font-size: 6px;
          color: #ffffff;
          text-align: center;
        }
      }
    }
  }
  .qrPage {
    margin-bottom: 20px;
    .logout {
      font-size: 14px;
      color: #fff;
      text-align: right;
      width: 90%;
      height: 20px;
      line-height: 20px;
      padding-bottom: 10px;
      // text-decoration: underline;
      .logout_text {
        border-bottom: solid #fff 1px;
      }
    }
    .van-hairline--bottom::after {
      border-bottom-width: 0;
    }
    /deep/ .van-nav-bar .van-icon {
      color: #fff;
    }
    .van-nav-bar {
      z-index: 999;
      background-color: transparent;
    }
    /deep/ .vue-qr {
      width: 50%;
      margin: 10px 25%;
      image {
        text-align: center;
      }
    }
    .reminder {
      text-align: center;
      font-size: 16px;
      color: #fff;
      // margin-bottom: 0px;
      .button {
        background-image: url("../../assets/image/userbutton.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 108px;
        height: 28px;
        font-size: 14px;
        font-weight: bold;
        line-height: 28px;
        margin: 0 auto;
        text-align: center;
        color: #ffffff;
      }
      div {
        margin: 10px 0;
      }
    }
    .matters {
      width: 88%;
      margin: 40px auto 0 auto;
      padding: 15px 15px 30px 15px;
      box-sizing: border-box;
      background-color: #3c2455;
      border-radius: 4px;
      .title {
        width: 80%;
        margin: 0 auto;
        padding: 10px;
        text-align: center;
        color: #fff;
        font-size: 16px;
        border-bottom: 1px solid #b4b4b4;
      }
      .cell {
        width: 80%;
        margin: 0 auto;
        padding: 10px;
        display: flex;
        color: #fff;
        font-size: 16px;
        border-bottom: 1px solid #b4b4b4;
        justify-content: space-between;
      }
    }
  }
  .expectPage {
    height: 50%;
    margin-top: 25%;
    text-align: center;
    .expect {
      color: #fff;
      font-size: 35px;
    }
  }
  .remindPage {
    /deep/ .van-popup--center {
      width: 82%;
      background-color: inherit;
    }
    .block {
      width: 99%;
      height: 450px;
      background-color: #62344e;
      color: #fff;
      overflow: scroll;
      // border-top: #fff solid 1px;
      // border-left: #fff solid 1px;
      // border-right: #fff solid 1px;
      // border: #fff solid 1px;
      background-image: url("../../assets/image/remind_bg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .remind_title {
        font-size: 24px;
        text-align: center;
        height: 50px;
        line-height: 50px;
      }
      .remind_content {
        font-size: 16px;
        height: 380px;
        width: 90%;
        margin: 0 auto;
        line-height: 24px;
        padding-bottom: 10px;
        white-space: pre-wrap;
      }
    }
    .mealsBox {
      background-image: url("../../assets/image/meals_bg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .mealsList {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        font-size: 14px;
        text-align: center;
        color: #fff;
        div{
          flex: none;
          line-height: 16px;
          display: flex;
          justify-content:center;
          align-items:center;
          padding: 5px 0;
        }
        .mealsList_title{
          color: #f0906d;
          height: 40px;
        }
        .date{
          width: 15%;
        }
        .time{
          width: 30%;
        }
        .place{
          width: 30%;
        }
        .meals{
          width: 25%;
        }
      }
      .nealsBuy{
        font-size: 16px;
        color: #f0906d;
        text-align: center;
        padding: 5px 0;
      }
      .mealsTips{
        color: #f0906d;
        font-size: 10px;
        width: 95%;
        margin: 0 auto;
        padding: 10px 0;
      }
    }
    .close {
      height: 50px;
      width: 99%;
      display: flex;
      justify-content: center;
      align-items: center;
      // border-top: #fff solid 1px;
      img {
        width: 25px;
        height: 25px;
      }
    }
  }
}
.footer-fill {
  height: 37px;
}
</style>